import { QueryClient } from "react-query";
import { toast } from "react-toastify";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchInterval: 0,
            refetchOnReconnect: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            retry: false,
            retryOnMount: false,
            onError: (e) => {
                console.error(e);
                toast('Uh oh. Something has gone wrong.', { type: 'error' }) 
            }
        },
    }
});