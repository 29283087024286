import styled from "styled-components"
import { Loader, Message, Tag } from "components";
import { formatDate } from "util/format";
import { usePosts } from "../hooks/usePosts";
import { renderRichText } from "lib/contentful";
import { FacebookShareButton, FacebookIcon } from 'react-share';
import { BASE_URL } from "config";

const Feed = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
    flex-grow: 2;
    max-width: 36rem;
    min-width: 30rem;
    @media only screen and (max-width: 50rem) {
        max-width: unset;
        min-width: unset;
    }
`;

const Post = styled.div`
    background: white;
    border-radius: .5rem;
    overflow: hidden;
    padding: 1rem;

    .actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--dark);
        padding-bottom: 8px;

        .share-btn {
            border: 1px solid var(--dark) !important;
            padding: 4px !important;
            border-radius: 4px !important;
            display: flex;
            flex-direction: row;
            align-items: center;

            &:hover {
                background: var(--light) !important;
            }

            svg {
                margin-right: 8px;
            }
        }
    }

    ${Tag} {
        height: fit-content
    }

    img {
        width: 100%;
        cursor: pointer;
        box-shadow: var(--shadow);
    }

    p {
        margin: 1rem 0;

        &.date {
            font-weight: bold;
        }
    }

    @media (max-width: 50rem) {
        width: 100%;
    }
`

export const PostFeed = ({ id }) => {
    const { data, isLoading } = usePosts(id);

    if (isLoading) return <Loader text="Loading posts..." />

    return (
        <section>
            <Feed>
                {data.items.map(post => (
                        <Post key={post.sys.id}>
                            <div className="actions">
                                <Tag color="var(--light)" className="date">Posted: {formatDate(post.sys.createdAt)}</Tag>
                                <FacebookShareButton
                                    className="share-btn"
                                    url={`${BASE_URL}/projects/${id}/posts/${post.sys.id}`}
                                    quote={`Check out this post on JonPost.com: "${post.fields.title}"!`}
                                     >
                                        <FacebookIcon size={32} round />
                                    Share
                                </FacebookShareButton>
                            </div>
                            <h3 className="title">{post.fields.title}</h3>
                            <div className="description">{renderRichText(post.fields.description)}</div>
                        </Post>
                    )
                )}
                {data.total === 0 && <Message>No posts yet!</Message>}
            </Feed>
        </section>
    );
}