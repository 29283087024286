import styled from 'styled-components';

const HeaderWrapper = styled.header`
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 80px;

    @media (max-width: 50rem) {
        flex-direction: column;
        height: unset;
        > * {
            height: 40px;
        }
    }

    img {
        height: 64px;
    }

`

const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > a, > [role="link"] {
        height: 2rem !important;
        background: transparent;
        outline: none;
        border: none;
        font-size: 1rem;
        text-decoration: none;
        cursor: pointer;
        color: var(--link);
        display: flex;
        justify-content: center;
        align-items: center;
        width: min(128px, calc(100vw / 3));
        
        &:hover {
            color: var(--link-hover);
            backdrop-filter: brightness(.8);
        }
    }

    > a:last-of-type, > [role="link"]:last-of-type {
        margin-right: 0;
    }
`;

export const Header = () => {
    return (
        <HeaderWrapper>
            <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="jonpost.com logo" />
            <Nav>
                <a href="/#">Home</a>
                <a href="/#projects">Projects</a>
                <a href="/#about">About</a>
            </Nav>
        </HeaderWrapper>
    )
}