import { Helmet } from 'react-helmet';

export const Head = ({ title, description, image, url}) => (
    <Helmet
        title={title ? `JonPost.com - ${title}` : undefined}
        defaultTitle="JonPost.com">
        <meta property="og:type" content="article" />
        { title && <meta property="og:title" content={`${title}`} />}
        { description && <meta name="description" content={`${description}`} /> }
        { description && <meta property="og:description" content={`${description}`} /> }
        { image && <meta property="og:image" content={`${image}`} />}
        { image && <meta property="twitter:card" content="summary_large_image" />}
        { url && <meta property="og:url" content={`${url}`} /> }
    </Helmet>
)