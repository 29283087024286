import styled, { keyframes } from "styled-components"

const spinnerAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const SpinnerWrapper = styled.div`
    width: 100%;
    align-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 32px 0;
`

const Spinner = styled.div`
    margin-bottom: 32px;
    &:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #fff;
        border-color: #fff transparent var(--brown) transparent;
        animation: ${spinnerAnimation} 1.2s linear infinite;
    }
`;

export const Loader = ({text = 'Loading...'}) => (
    <SpinnerWrapper>
        <Spinner />
        { text }
    </SpinnerWrapper>
);