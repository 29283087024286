import { useEffect, useState } from "react";

const getDataUrl = (img) => {
    // Create canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    // Set width and height
    canvas.width = img.width;
    canvas.height = img.height;
    // Draw the image
    ctx?.drawImage(img, 0, 0);
    return canvas.toDataURL('image/jpeg');
 }

export const usePreloadImage = (url) => {
    const [ image, setImage ] = useState(null);
    const [ loaded, setLoaded ] = useState(false);

    useEffect(() => {
        const pendingImage = new Image();
        pendingImage.onload = () => {
            setLoaded(true);
            setImage(getDataUrl(pendingImage));
        }
        pendingImage.src = url;
    }, [ url ]);

    return { image, loaded };
};