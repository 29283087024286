const { default: styled } = require("styled-components");

export const ViewContainer = styled.div`
    padding: 5rem;
    display: flex;
    flex-direction: ${props => props.row ? 'row' : 'column'};
    justify-content: space-evenly;

    @media only screen and (max-width: 50rem) {
        padding: 2rem;
        flex-direction: column;
    }

    @media only screen and (max-width: 37.5rem) {
        padding: 2rem 1rem;
    }
`;