import { client } from "lib/contentful"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

/**
 * 
 * @param {string} postId 
 * @returns {import("react-query").UseQueryResult}
 */
export const usePost = (postId) => {
    const navigate = useNavigate();

    return useQuery(
        ['posts', postId],
        () => client.getEntry(postId),
        {
            onError: (e) => {
                console.error('');
                navigate('/');
                toast('Uh oh. Something went wrong.', { type: 'error', autoClose: true });
            }
        }
    );
}