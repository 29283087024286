import { createRef, useEffect, useState } from "react"

/**
 * 
 * @param {HTMLElement} element 
 * @returns 
 */
function isInViewport(element) {
    if (!element) return;
    const elementTop = element.offsetTop;
    const elementBottom = element.offsetTop + element.offsetHeight;
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    return (
        scrollY + windowHeight > elementTop && scrollY < elementBottom
    );
}

export const useInViewport = (once = false) => {
    const [ inViewport, setInViewPort ] = useState(false);
    /** @type {React.Ref<HTMLElement>} */
    const ref = createRef();

    useEffect(() => {
        const scrollListener = () => {
            if (ref.current && isInViewport(ref.current)) {
                setInViewPort(true);
                if (once) {
                    window.removeEventListener('scroll', scrollListener);
                }
            } else {
                setInViewPort(false);
            }
        };
        window.addEventListener('scroll', scrollListener);

        return () => {
            window.removeEventListener('scroll', scrollListener);
        }

    }, [ ref, once ])

    return { ref, inViewport };
}