import styled from "styled-components";
import { Outlet, useParams } from "react-router-dom"
import { PostFeed, ProjectCard } from "../components";
import { Loader, ViewContainer } from "components";
import { useProject } from "../hooks/useProject";
import { Head } from "components/Head";
import { BASE_URL } from "config";

const ProjectSummary = styled.aside`
    min-width: 18rem;
    max-width: 20rem;
    margin-bottom: 2rem;
    margin-right: 2rem;
    position: sticky;
    top: 32px;
    align-self: flex-start;
    
    @media only screen and (max-width: 50rem) {
        position: unset;
        top: unset;
        align-self: unset;

        max-width: unset;
        min-width: unset;
        margin-right: unset;

        ::after {
            display: block;
            content: '';
            padding-bottom: 2rem;
            border-bottom: 4px dotted var(--dark);
        }
    }
`;

export const Project = () => {
    const { id } = useParams();

    const { data, isLoading } = useProject(id);

    if (isLoading) return <Loader text="Loading project" />;

    return (
        <ViewContainer row>
            <Head
                title={data.fields.name}
                description={data.fields.description}
                image={data.fields.previewImage.fields.file.url}
                url={`${BASE_URL}/projects/${id}`}
                />
            <ProjectSummary>
                <ProjectCard id={id} project={data} showLink={false} />
            </ProjectSummary>
            <PostFeed id={id} />
            <Outlet />
        </ViewContainer>
    )
}