import { Button, Card, Tag } from "components";
import styled from "styled-components";
import { formatDate } from "util/format";

const CardWrapper = styled(Card)`
    margin: 0;
    ${Card.Content} {

        margin-bottom: 2rem;

        ${Tag} {
            margin: .5rem 0;
        }

        .description {
            margin: 1rem 0;
        }

        .date {
            font-weight: bold;
            position: absolute;
            bottom: .5rem;
        }
    }
`;

export const ProjectCard = ({id, project, className, showLink = true }) => {
    if (!project) return null;
    return (
        <CardWrapper className={className} >
            <Card.Image src={project.fields.previewImage.fields.file.url} alt={project.fields.previewImage.description} />
            <Card.Title>{project.fields.name}</Card.Title>
            <Card.Content>
                <p className="description">{ project.fields.description }</p>
                { showLink && <Button as="a" href={`/projects/${id}`}>View Posts</Button> }
                <p className="date">Last Updated: {formatDate(project.sys.createdAt)}</p>
            </Card.Content>
        </CardWrapper>
    );
}