import { client } from "lib/contentful"
import { useQuery } from "react-query"

/**
 * 
 * @param {string} projectId 
 * @returns {import("react-query").UseQueryResult}
 */
export const usePosts = (projectId) => {
    return useQuery(
        ['posts', projectId],
        () => client.getEntries({ content_type: 'post', 'fields.parentProject.sys.id': projectId } ));
}