import { client } from "lib/contentful"
import { useQuery } from "react-query"

/**
 * 
 * @param {string} projectId 
 * @returns {import("react-query").UseQueryResult}
 */
export const useProject = (projectId) => {
    return useQuery(
        ['projects', projectId],
        () => client.getEntry(projectId, { content_type: 'project' } ));
}