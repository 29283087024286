import styled from 'styled-components';
import { Loader } from 'components';
import { useInViewport } from 'hooks/useInViewport';
import { ProjectCard } from './ProjectCard';
import { useProjects } from '../hooks/useProjects';

const ProjectCardList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-auto-columns: auto;
    grid-gap: 4rem;
    row-gap: 2rem;

    @media (max-width: 37.5rem) {
        grid-template-columns: repeat(auto-fill, minmax(calc(100vw - 5rem), 1fr));
    }
`;

export const ProjectList = () => {
    const { data, isLoading } = useProjects();
    const { ref, inViewport } = useInViewport(true);

    if (isLoading) return <Loader />;

    return (
        <ProjectCardList ref={ref}>
            { data.items.map(project => {
                return (
                    <ProjectCard key={project.sys.id} id={project.sys.id} project={project} className={inViewport && 'slide-in-down'} />
                );
            }) }
        </ProjectCardList>
    )
}