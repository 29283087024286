import styled, { keyframes } from "styled-components";

const wiggle = keyframes`
    0% { transform: rotate(0deg); }
   25% { transform: rotate(-2deg); }
   75% { transform: rotate(2deg); }
  100% { transform: rotate(0deg); }
`;

export const Button = styled.button`
    outline: none;
    border: none;
    padding: .5rem 1rem;
    border-radius: 8px;
    background: var(--success);
    cursor: pointer;
    font-weight: bold;
    transition: .2s border-radius;
    text-decoration: none;
    color: var(--light-text);
    margin-bottom: .5rem;

    &:hover {
        border-radius: 0;
        filter: brightness(.85);
        animation: ${wiggle} .2s linear forwards;
    }
`;

const IconButtonWrapper = styled.button`
    outline: none;
    border: none;
    height: 40px;
    width: 40px;
    background: transparent;
    color: inherit;

    .icon {
        height: 100%;
        width: 100%;
        cursor: pointer;
    }

    &:hover {
        background: rgba(0, 0, 0, .2);
    }
`;

export const IconButton = ({ onClick, component: Component, children, ...rest }) => {
    return (
        <IconButtonWrapper onClick={onClick} {...rest} >
            <Component className="icon" />
        </IconButtonWrapper>
    );
}