import styled from "styled-components";
import { findByType } from "util/findByType";

const CardWrapper = styled.div`
    position: relative;
    max-width: 100%;
    overflow: hidden;

    box-shadow: var(--shadow);
    background: white;
    border-radius: 16px;
`;

const Image = styled.img`
    width: 100%;
    height: 16rem;
    background: var(--brown);
    object-fit: cover;
`;


const Title = styled.h2`
    font-weight: bold;
    font-size: 1.4em;
    margin-bottom: .5rem;

    ${Image} + & {
        margin-top: 1rem;
    }
`;

const ContentWrapper = styled.div`
    border-radius: 0 0 8px 8px;
    padding: 1.5rem;
`;

const Content = styled.div`
    line-height: 1.5;
`;

export const CardList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const Card = ({ className, children }) => {
    
    const renderImage = () => {
        return findByType(children, Image);
    }
    const renderTitle = () => {
        return findByType(children, Title);
    }
    
    const renderContent = () => {
        return findByType(children, Content);
    }
    
    return (
        <CardWrapper className={className}>
            { renderImage() }
            <ContentWrapper>
                { renderTitle() }
                { renderContent() }
            </ContentWrapper>
        </CardWrapper>
    );
}


Image.displayName = 'CardImage';
Card.Image = Image;
Title.displayName = 'CardTitle';
Card.Title = Title;
Content.displayName = 'CardContent';
Card.Content = Content;