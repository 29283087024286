import { Footer, Header } from "../components/Layout"
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "features/misc/routes/ErrorFallback";
import { queryClient } from "lib/reactQuery";
import { ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';

export const AppProvider = ({ children }) => {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => window.location.reload()}>

            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <div className="App">
                        <Header />
                        <main className="grainy-bg">
                            {children}
                        </main>
                        <Footer>All rights reserved Jon's Workshop &trade;</Footer>
                    </div>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={false} />
                </BrowserRouter>
            </QueryClientProvider>
        </ErrorBoundary>
    );
}