import { Loader, Modal } from "components"
import { Head } from "components/Head";
import { BASE_URL } from "config";
import { renderRichText } from "lib/contentful";
import { useNavigate, useParams } from "react-router-dom"
import { usePost } from "../hooks/usePost";

export const PostModal = () => {
    const navigate = useNavigate();
    const { id, postId } = useParams();
    const { data, isLoading, isError } = usePost(postId);

    const closeModal = () => {
        navigate(`/projects/${id}`);
    }

    if (isError || !data) return null;

    return (
        <Modal title={isLoading ? 'Loading...' : data.fields.title} show={true} closeModal={closeModal}>
            { !isLoading &&
                <Head
                    title={data.fields.title}
                    url={`${BASE_URL}/projects/${id}/posts/${postId}`}
                />
            }
            { isLoading ? <Loader /> : renderRichText(data.fields.description) }
        </Modal>
    )
}