import { Route, Routes } from "react-router-dom";
import { Home } from "features/misc";
import { Project } from "features/project";
import { PostModal } from "features/project/components/PostModal";

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projects/:id" element={<Project />}>
                <Route path="posts/:postId" element={<PostModal />} />
            </Route>
        </Routes>
    );
}