import { client } from "lib/contentful"
import { useQuery } from "react-query"

/**
 * 
 * @returns {import("react-query").UseQueryResult}
 */
export const useProjects = () => {
    return useQuery(
        'projects',
        () => client.getEntries({ content_type: 'project' }));
}