import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from "styled-components";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { IconButton } from './';
import FocusTrap from 'focus-trap-react';

const modalRoot = document.querySelector('#modal-root');

const fadeIn = keyframes`
    from {
        background: rgba(0, 0, 0, 0);
    }

    to {
        background: rgba(0, 0, 0, .5);
    }
`

const ModalBackground = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    animation: ${fadeIn} .2s linear forwards;
`;

const ModalWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    `;


const ModalBody = styled.div`
    min-width: 40vw;
    max-width: 60vw;
    box-shadow: var(--shadow);
    border-radius: 0 0 8px 8px;
    background: var(--light);
    z-index: 1001;

    @media (max-width: 50rem) {
        max-width: 80vw;
    }
`;

const ModalHeader = styled.div`
    padding: 0 24px;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--shadow-below);
    background: var(--brown);
    color: var(--light);
`;

const ModalContent = styled.div`
    padding: 2rem;
    min-height: 400px;
    max-height: 80vh;
    overflow-y: scroll;
`;

const ModalFooter = styled.div`
    height: 5rem;
    border-top: 1px solid var(--dark);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

export const Modal = ({ show, title, closeModal, actionButton, children, ...rest }) => {

    const modalElement = useRef(document.createElement('div'));

    useEffect(() => {
        // Have to copy this into closure or cleanup function could get NPE
        const el = modalElement.current;
        modalRoot.appendChild(el);
        return () => {
            modalRoot.removeChild(el);
        }
    }, [modalElement])

    useEffect(() => {
        if (show) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
        
        return () => {
            if (show) document.body.classList.remove('no-scroll');
        }
    }, [ show ]);

    if (!show) return null;
    
    return createPortal(
        <FocusTrap>
            <ModalWrapper {...rest}>
                <ModalBackground onClick={closeModal} />
                <ModalBody role="dialog">
                    <ModalHeader>
                        <h2 className="title">{title}</h2>
                        { closeModal && <IconButton onClick={closeModal} component={AiOutlineCloseCircle} />}
                    </ModalHeader>
                    <ModalContent>{ children }</ModalContent>
                    { actionButton && <ModalFooter>{actionButton}</ModalFooter>}
                </ModalBody>
            </ModalWrapper>
        </FocusTrap>,
        modalElement.current
    )
}